import axios from "axios";
import { toast } from "react-toastify";
import logout from "../helpers/logout";

const token = JSON.parse(localStorage.getItem('JellyWorkzApiToken') as string)

const dataAxios = {
    // baseURL: process.env.NODE_ENV !== 'production' ? 'https' : 'https',
    headers: token ? { Authorization: `Bearer ${token}` } : undefined,
    baseURL: 'https://alefgeo.zdras.te.ua/api',
};

const instance = axios.create(dataAxios);

// instance.defaults.headers.get['Access-Control-Allow-Origin'] = '*';
// instance.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
// instance.defaults.headers.put['Access-Control-Allow-Origin'] = '*';

instance.interceptors.response.use(
    response => response,
    error => {
        console.error("[error]", error)
        const status = error.request.status
        if (status === 401) {
            const errorData = error.request.response
            const errorType = JSON.parse(errorData)
            if (errorType.errors.text === "The text field is required.") {
                logout()
            }
        } else if (status === 422) {
            const errorData = error.request.response
            const errorType = JSON.parse(errorData)
            for (const [key, value] of Object.entries(errorType.errors)) {
                toast.error(value as string);
            }
        }
        return Promise.reject(error)
    }
)

export default instance