import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import { IAllSubjectsProps } from './interfaces';
import styles from './index.module.scss';

const AllSubjects: React.FC<IAllSubjectsProps> = ({ breadcrumbsTitle, panelTitle, itemArray, panelAdd, deleteItem, setCurrentItem }) => (
  <div className={styles.content}>
    <div className={`${styles.panel} ${styles.contentHeader}`}>
      <div className={styles.panelBody}>
        <h3 className={styles.breadcrumbsTitle}>{breadcrumbsTitle}</h3>
        <p className={styles.breadcrumbs}>
          <NavLink to="/">Консоль</NavLink>
          <FontAwesomeIcon icon={faAngleRight} /> Вакансии
        </p>
      </div>
    </div>
    <div className={`${styles.panel} ${styles.box}`}>
      <h3 className={styles.panelTitle}>{panelTitle}</h3>
      <NavLink className={styles.panelAdd} to={panelAdd.link} target="_blank" rel="noopener noreferrer">
        {panelAdd.text}
      </NavLink>
    </div>
    <div className={`${styles.panelBody} ${styles.panel}`}>
      <div className={styles.all}>
        {itemArray && itemArray.length > 0 && <ul>
          {itemArray.map((item) => <li key={item.id}>
            {item.name}
            <div className={styles.redacted}>
              <FontAwesomeIcon onClick={() => setCurrentItem(item as any)} className={styles.edit} icon={faEdit} />
              <FontAwesomeIcon onClick={() => deleteItem(item.id)} className={styles.trash} icon={faTrash} />
            </div>
          </li>
          )}
        </ul>}
      </div>
    </div>
  </div>
);

export default AllSubjects;