import { RouteComponentProps, withRouter, } from "react-router";
import SideBar from ".";
import styles from './index.module.scss';


const SideBarContainer: React.FC<RouteComponentProps> = ({location}) => {

  const getNavLinkClass = (path: Array<string>) => {
    return path.includes(location.pathname) ? ` ${styles.active}` : '';
  }

  return <SideBar getNavLinkClass={getNavLinkClass}/>
}

export default withRouter(SideBarContainer);