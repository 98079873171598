import { useState } from 'react';
import { RichTextEditor } from '@mantine/rte';
import styles from './index.module.scss';
import { IProjectBody } from './interfaces';
import { ProjectApi } from '../../api/api';

const ProjectBody: React.FC<IProjectBody> = ({ id, text, textEn, setText, setTextEn, save, publish, edit, cancel }) => {
  return <div className={`${styles.panelBody} ${styles.panel}`}>
    <h3>Описание проекта</h3>
    <div>
      <h4 className={styles.title}>
        Украинский язык
      </h4>
      <RichTextEditor
        className={styles.textEditor}
        value={text}
        onChange={(value) => setText(value)}
        controls={[
          ['bold', 'italic', 'underline', 'link'],
          ['image', 'video'],
          ['h2', 'h3', 'h4'],
          ['unorderedList', 'orderedList'],
          ['sup', 'sub'],
          ['alignLeft', 'alignCenter', 'alignRight'],
        ]}
        onImageUpload={ProjectApi.uploadImage}
      />
    </div>
    <div>
      <h4 className={styles.title}>
        Англиский язык
      </h4>
      <RichTextEditor
        className={styles.textEditor}
        value={textEn}
        onChange={(value) => setTextEn(value)}
        controls={[
          ['bold', 'italic', 'underline', 'link'],
          ['image', 'video'],
          ['h2', 'h3', 'h4'],
          ['unorderedList', 'orderedList'],
          ['sup', 'sub'],
          ['alignLeft', 'alignCenter', 'alignRight'],
        ]}
        onImageUpload={ProjectApi.uploadImage}
      />
    </div>
    <div id="save-action">
      {/* <input type="submit" name="save" id="save-vacancy" value="Сохранить" className="button" /> */}
      {edit ? <>
        <button className="button" onClick={() => edit(id as number)} >Изменить</button>
        <button className="button" onClick={cancel} >Отменить</button>
      </>
        : <>
          <button className="button" onClick={save} >Сохранить</button>
          <button className="button" onClick={publish}>Опубликовать</button>
        </>
      }
    </div>
  </div>
}

export default ProjectBody;