import { ITwoLang } from '../components/AddSubjects/interfaces';
import instance from './instance';

const createFormData = (body: { [key: string]: string | number | Blob | Array<any> }) => {
    const formData = new FormData()
    for (const [key, value] of Object.entries(body)) {
        if (key === 'image') {
            formData.append(key, value as File)
        }
        else if (value) {
            formData.append(key, value.toString())
        }
    }
    return formData
}

export const AuthApi = {
    login(body: { email: string, password: string }) {
        return instance
            .post('/login', body)
            .then(response => {
                if (response.status === 200) {
                    if (response.data.data?.api_token) {
                        return response.data.data?.api_token
                    } else {
                        return false
                    }
                }
            })
            .catch(() => {
                return false
            })
    }
}

export const BlogApi = {
    getBlogList() {
        return instance
            .get('/blog')
            .then(response => response.data)
            .catch(() => false)
    },
    createBlog(body: { text: string, text_en: string, name: string, name_en: string, image?: Blob }) {
        return instance
            .post('/blog', createFormData(body))
            .then(response => response.data)
            .catch(() => false)
    },
    deleteBlog(id: number) {
        return instance
            .delete(`/blog/${id}`)
            .then(response => response.data)
            .catch(() => false)
    },
    editBlog(id: number, body: { text: string, name_en: string, name: string, text_en: string, image?: Blob }) {
        return instance
            .post(`/blog/${id}`, createFormData(body))
            .then(response => response.data)
            .catch(() => false)
    }
}

export const VacancyApi = {
    getVacancyList() {
        return instance
            .get('/vacancy')
            .then(response => response.data)
            .catch(() => false)
    },
    createVacancy(body: { name: string, skills: Array<string>, requirements: Array<ITwoLang> }) {
        return instance
            .post('/vacancy', body)
            .then(response => response.data)
            .catch(() => false)
    },
    deleteVacancy(id: number) {
        return instance
            .delete(`/vacancy/${id}`)
            .then(response => response.data)
            .catch(() => false)
    },
    editVacancy(id: number, body: { skills: Array<{ name: string }>, requirements: Array<ITwoLang>, name: string }) {
        return instance
            .post(`/vacancy/${id}`, body)
            .then(response => response.data)
            .catch(() => false)
    }
}

export const ProjectApi = {
    getProjectList() {
        return instance
            .get('/portfolio')
            .then(response => response.data)
            .catch(() => false)
    },
    createProject(body: { text: string, text_en: string, name: string, name_en: string, image?: Blob }) {
        return instance
            .post('/portfolio', createFormData(body))
            .then(response => response.data)
            .catch(() => false)
    },
    deleteProject(id: number) {
        return instance
            .delete(`/portfolio/${id}`)
            .then(response => response.data)
            .catch(() => false)
    },
    editProject(id: number, body: { text: string, text_en: string, name: string, name_en: string, image?: Blob }) {
        return instance
            .post(`/portfolio/${id}`, createFormData(body))
            .then(response => response.data)
            .catch(() => false)
    },
    uploadImage(file: File): Promise<string> {
        return instance
            .post(`/portfolio/upload-image`, createFormData({ image: file }))
            .then(response => response.data.data.quality)
            .catch(() => new Error('Upload failed'))
    }
}