import AllSubjects from '../../components/AllSubjects';
import styles from './index.module.scss';
import { IAllPost } from './interfaces';

const AllPost: React.FC<IAllPost> = ({ postList, deleteItem, setCurrentItem }) => (
  <AllSubjects
    panelAdd={{ link: '/add-post', text: 'Добавить статью' }}
    breadcrumbsTitle={'Статьи'}
    panelTitle={'Все статьи'}
    itemArray={postList}
    deleteItem={deleteItem}
    setCurrentItem={setCurrentItem as any}
  />
);

export default AllPost;