import AllSubjects from '../../components/AllSubjects';
import styles from './index.module.scss';
import { IAllVacancy } from './interfaces';

const AllVacancy: React.FC<IAllVacancy> = ({ vacancyList, deleteItem, setCurrentItem }) => (
  <AllSubjects
    panelAdd={{ link: '/add-vacancy', text: 'Добавить вакансию' }}
    breadcrumbsTitle={'Вакансии'}
    panelTitle={'Все вакансии'}
    itemArray={vacancyList}
    deleteItem={deleteItem}
    setCurrentItem={setCurrentItem}
  />
);

export default AllVacancy;