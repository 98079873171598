import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from '@fortawesome/free-solid-svg-icons'
import styles from './index.module.scss';

const Dashboard = () => (
  <div className={styles.wrapper}>
    <div className={styles.panel}>
      <div className={styles.panelHeading}>
        <div>
          <h4>Visit</h4>
        </div>
        <div>
          <FontAwesomeIcon icon={faUser} />
        </div>
      </div>
      <div className={styles.panelBody}>
        <h3>51181,320</h3>
        <p>User active</p>
        <hr />
      </div>
    </div>
  </div>
);

export default Dashboard;