import { useState } from "react";
import Login from ".";
import { IHandleLogin, ILoginContainer } from "./interfaces";
import WithPreloader from "../../hoc/WithPreloader";
import { compose } from "redux";
import { IWithPreloader } from "../../hoc/WithPreloader/interfaces";
import { AuthApi } from "../../api/api";
import { toast } from "react-toastify";


const LoginContainer: React.FC<ILoginContainer & IWithPreloader> = ({ setLoading, setToken }) => {
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [isChecked, setChecked] = useState<boolean>(false)

  const handleLogin: IHandleLogin = async (email, password) => {
    setLoading(true)
    const token = await AuthApi.login({ email, password })
    setLoading(false)
    if (typeof token === 'string') {
      setToken(token)
    } else {
      toast.error('Incorrect email or password')
      setChecked(true)
    }
  }

  return <Login
    email={email}
    setEmail={setEmail}
    password={password}
    setPassword={setPassword}
    handleLogin={handleLogin}
    isChecked={isChecked}
    setChecked={setChecked}
  />
}

export default compose<any>(WithPreloader)(LoginContainer);