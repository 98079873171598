import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { compose } from "redux";
import { useHistory } from "react-router-dom";
import { ITwoLang } from "../../components/AddSubjects/interfaces";
import WithPreloader from "../../hoc/WithPreloader";
import { IWithPreloader } from "../../hoc/WithPreloader/interfaces";
import WithSideBar from "../../hoc/WithSideBar";
import { VacancyApi } from "../../api/api";
import AddVacancy from ".";

const AddVacancyContainer: React.FC<IWithPreloader> = ({ setLoading }) => {
  const [title, setTitle] = useState<string>('')
  const [skills, setSkills] = useState<Array<string>>([])
  const [requirements, setRequirements] = useState<Array<ITwoLang>>([])
  const history = useHistory();

  const publish = async () => {
    if (title && skills.length > 0 && requirements.length > 0) {
      setLoading(true)
      localStorage.removeItem('add-vacancy')
      await VacancyApi.createVacancy({ name: title, requirements, skills })
      setLoading(false)
      history.push('/all-vacancy')
    } else {
      toast.error('Fill in all fields')
    }
  }

  const save = () => {
    localStorage.setItem('add-vacancy', JSON.stringify({ title, skills, requirements }))
    toast.success('Saved')
  }

  useEffect(() => {
    const cashedData = JSON.parse(localStorage.getItem('add-vacancy') as string)
    if (cashedData) {
      setTitle(cashedData.title)
      setSkills(cashedData.skills)
      setRequirements(cashedData.requirements)
    }
  }, [])

  return <AddVacancy
    title={title}
    skills={skills}
    requirements={requirements}
    setTitle={setTitle}
    setSkills={setSkills}
    setRequirements={setRequirements}
    save={save}
    publish={publish}
  />
}

export default compose<any>(WithSideBar, WithPreloader)(AddVacancyContainer);