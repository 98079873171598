import styles from './index.module.scss';
import { IPanelFileInput } from './interfaces';

const PanelFileInput: React.FC<IPanelFileInput> = ({ fileInput, setFileInput }) => (
  <div className={`${styles.panel} ${styles.box}`}>
    <div className={styles.panelHeading}>
      <label htmlFor="img">Добавить изображение: </label>
      <input
        type="file"
        id="img"
        name="img"
        accept="image/png, image/jpeg"
        onChange={e => {
          // @ts-ignore
          setFileInput(e.target.files[0]);
        }}
      />
    </div>
  </div>
);

export default PanelFileInput;