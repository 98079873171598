import { ToastContainer } from 'react-toastify';
import Routes from './routes';
import 'react-toastify/dist/ReactToastify.css';

const App = () => (
<>
  <Routes />
  <ToastContainer position='bottom-right' />
</>
)

export default App;