import styles from '../../components/AddSubjects/index.module.scss';
import { IPanelSubjectTitle, IVacancyBody } from '../../components/AddSubjects/interfaces';
import PanelHeader from '../../components/AddSubjects/panelHeader';
import PanelSubjectTitle from '../../components/AddSubjects/panelSubjectTitle';
import VacancyBody from '../../components/AddSubjects/vacancyBody';

const AddVacancy: React.FC<IPanelSubjectTitle & IVacancyBody> = ({ title, skills, requirements, setSkills, setRequirements, setTitle, save, publish }) => (
  <div className={styles.content}>
    <PanelHeader location={'Добавить вакансию'} />
    <PanelSubjectTitle
      title={title}
      titlePlaceholder={'Название вакансии'}
      setTitle={setTitle}
    />
    <VacancyBody
      skills={skills}
      requirements={requirements}
      save={save}
      publish={publish}
      setSkills={setSkills}
      setRequirements={setRequirements}
    />
  </div>
);

export default AddVacancy;