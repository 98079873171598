import styles from './index.module.scss';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'

const PanelHeader: React.FC<{ location: string }> = ({ location }) => (
    <div className={`${styles.panel} ${styles.contentHeader}`}>
        <div className={styles.panelBody}>
            <h3 className={styles.breadcrumbsTitle}>{location}</h3>
            <p className={styles.breadcrumbs}>
                <NavLink to="/">Консоль</NavLink>
                <FontAwesomeIcon icon={faAngleRight} />
                {location}
            </p>
        </div>
    </div>
);

export default PanelHeader;