import { useEffect, useState } from "react";
import { compose } from "redux";
import AllVacancy from ".";
import { VacancyApi } from "../../api/api";
import WithPreloader from "../../hoc/WithPreloader";
import styles from "../../components/AddSubjects/index.module.scss";
import { IWithPreloader } from "../../hoc/WithPreloader/interfaces";
import WithSideBar from "../../hoc/WithSideBar";
import { IVacancy } from "./interfaces";
import PanelHeader from "../../components/AddSubjects/panelHeader";
import PanelSubjectTitle from "../../components/AddSubjects/panelSubjectTitle";
import VacancyBody from "../../components/AddSubjects/vacancyBody";
import { ITwoLang } from "../../components/AddSubjects/interfaces";
import { toast } from "react-toastify";

const AllVacancyContainer: React.FC<IWithPreloader> = ({ setLoading }) => {

  const [vacancyList, setVacancyList] = useState<Array<IVacancy> | null>(null)
  const [currentItem, setCurrentItem] = useState<IVacancy | null>(null)
  const [title, setTitle] = useState<string>('')
  const [skills, setSkills] = useState<Array<string>>([])
  const [requirements, setRequirements] = useState<Array<ITwoLang>>([])

  useEffect(() => {
    getInitialData()
  }, [])

  useEffect(() => {
    if (currentItem) {
      setTitle(currentItem.name)
      setSkills(currentItem.skills.map(({ name }) => name))
      setRequirements(currentItem.requirements)
    }
  }, [currentItem])

  const getInitialData = async () => {
    setLoading(true)
    const list = await VacancyApi.getVacancyList()
    setVacancyList(list)
    setLoading(false)
  }

  const deleteItem = async (id: number) => {
    setLoading(true)
    await VacancyApi.deleteVacancy(id)
    const list = await VacancyApi.getVacancyList()
    setVacancyList(list)
    setLoading(false)
  }

  const editItem = async (id: number) => {
    setLoading(true)
    const newSkills: any = skills.map((skill) => ({ name: skill }))
    const body: { skills: Array<{ name: string }>, requirements: Array<ITwoLang>, name: string } = { name: title, skills: newSkills, requirements }
    const response = await VacancyApi.editVacancy(id, body)
    if (!response.success) {
      return setLoading(false)
    }
    const list = await VacancyApi.getVacancyList()
    setVacancyList(list)
    setCurrentItem(null)
    setLoading(false)
  }

  return currentItem ? <div className={styles.content}>
    <PanelHeader location={'Изменить вакансию'} />
    <PanelSubjectTitle
      title={title}
      titlePlaceholder={currentItem?.name}
      setTitle={setTitle}
    />
    <VacancyBody
      id={currentItem.id}
      skills={skills}
      requirements={requirements}
      setSkills={setSkills}
      setRequirements={setRequirements}
      edit={(id: number) => editItem(id)}
      cancel={() => setCurrentItem(null)}
    />
  </div>
    : <AllVacancy
      vacancyList={vacancyList}
      deleteItem={deleteItem}
      setCurrentItem={setCurrentItem}
    />
}

export default compose<any>(WithSideBar, WithPreloader)(AllVacancyContainer);