import styles from '../../components/AddSubjects/index.module.scss';
import { IPanelFileInput, IPanelSubjectTitle, IPostBody } from '../../components/AddSubjects/interfaces';
import PanelFileInput from '../../components/AddSubjects/panelFileInput';
import PanelHeader from '../../components/AddSubjects/panelHeader';
import PanelSubjectTitle from '../../components/AddSubjects/panelSubjectTitle';
import PostBody from '../../components/AddSubjects/postBody';

const AddPost: React.FC<IPanelFileInput & IPostBody & IPanelSubjectTitle> = ({ fileInput, text, title, textEn, titleEn, save, publish, setFileInput, setTextEn, setText, setTitle, setTitleEn }) => (
  <div className={styles.content}>
    <PanelHeader location={'Добавить статью'} />
    <PanelSubjectTitle
      title={title}
      titleEn={titleEn}
      titlePlaceholder={'Название статьи'}
      setTitle={setTitle}
      setTitleEn={setTitleEn}
    />
    <PanelFileInput
      fileInput={fileInput}
      setFileInput={setFileInput}
    />
    <PostBody
      text={text}
      textEn={textEn}
      setText={setText}
      setTextEn={setTextEn}
      save={save}
      publish={publish} />
  </div>
);

export default AddPost;