import { ChangeEvent } from 'react';
import TextInputContainer from '../../components/TextInput/container';
import styles from './index.module.scss';
import { ILogin } from './interfaces';

const Login: React.FC<ILogin> = ({ isChecked, email, password, setEmail, setPassword, handleLogin, setChecked }) => (
  <div className={styles.form}>
    <div className={styles.form_content}>
      <div className={styles.form_content__text}>
        Sign in
      </div>
      <TextInputContainer
        type="text"
        placeholder='Email'
        className={styles.textInput}
        value={email}
        onChange={(e: ChangeEvent<HTMLInputElement>) => { setEmail(e.target.value) }}
        isChecked={isChecked}
      />
      <TextInputContainer
        type="password"
        placeholder='Password'
        className={styles.textInput}
        value={password}
        onChange={(e: ChangeEvent<HTMLInputElement>) => { setPassword(e.target.value) }}
        isChecked={isChecked}
      />
      <button
        onClick={() => {
          if (email && password) {
            handleLogin(email, password)
          } else {
            setChecked(true)
          }
        }}
        className={styles.btn}
      >
        Login
      </button>
    </div>
  </div>
);

export default Login;