import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import TextInput from ".";
import { ITextInputContainer } from "./interfaces";


const TextInputContainer: React.FC<ITextInputContainer> = (props) => {

  return <TextInput
    {...props}
  />
}

export default TextInputContainer;