import { useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import NotFound from './pages/NotFound';
import LoginContainer from './pages/Login/container';
import PrivateRoute from "./hoc/PrivateRoute";
import DashboardContainer from "./pages/Dashboard/container";
import AllVacancyContainer from "./pages/AllVacancy/container";
import AllProjectContainer from "./pages/AllProject/container";
import AllPostContainer from "./pages/AllPost/container";
import AddVacancyContainer from "./pages/AddVacancy/container";
import AddProjectContainer from "./pages/AddProject/container";
import AddPostContainer from "./pages/AddPost/container";

const Routes = () => {

  return <Router>
    <Switch>
      <PrivateRoute exact path="/">
        <DashboardContainer/>
      </PrivateRoute>
      <PrivateRoute exact path="/all-vacancy">
        <AllVacancyContainer/>
      </PrivateRoute>
      <PrivateRoute exact path="/all-project">
        <AllProjectContainer/>
      </PrivateRoute>
      <PrivateRoute exact path="/all-post">
        <AllPostContainer/>
      </PrivateRoute>
      <PrivateRoute exact path="/add-vacancy">
        <AddVacancyContainer/>
      </PrivateRoute>
      <PrivateRoute exact path="/add-project">
        <AddProjectContainer/>
      </PrivateRoute>
      <PrivateRoute exact path="/add-post">
        <AddPostContainer/>
      </PrivateRoute>
      <PrivateRoute exact path="/login">
        <LoginContainer />
      </PrivateRoute>
      <Route>
        <NotFound />
      </Route>
    </Switch>
  </Router>
}

export default Routes