import { useEffect, useState } from "react";
import { compose } from "redux";
import AllPost from ".";
import { BlogApi } from "../../api/api";
import WithPreloader from "../../hoc/WithPreloader";
import { IWithPreloader } from "../../hoc/WithPreloader/interfaces";
import WithSideBar from "../../hoc/WithSideBar";
import { IPost } from "./interfaces";
import styles from "../../components/AddSubjects/index.module.scss";
import PanelHeader from "../../components/AddSubjects/panelHeader";
import PanelSubjectTitle from "../../components/AddSubjects/panelSubjectTitle";
import PanelFileInput from "../../components/AddSubjects/panelFileInput";
import PostBody from "../../components/AddSubjects/postBody";
import { toast } from "react-toastify";

const AllPostContainer: React.FC<IWithPreloader> = ({ setLoading }) => {

  const [postList, setPostList] = useState<Array<IPost> | null>(null)
  const [currentItem, setCurrentItem] = useState<IPost | null>(null)
  const [fileInput, setFileInput] = useState<Blob | null>(null)
  const [text, setText] = useState<string>('')
  const [textEn, setTextEn] = useState<string>('')
  const [title, setTitle] = useState<string>('')
  const [titleEn, setTitleEn] = useState<string>('')

  useEffect(() => {
    if (currentItem) {
      setTitle(currentItem.name)
      setTitleEn(currentItem.name_en)
      setText(currentItem.text)
      setTextEn(currentItem.text_en)
    }
  }, [currentItem])

  useEffect(() => {
    getInitialData()
  }, [])

  const getInitialData = async () => {
    setLoading(true)
    const list = await BlogApi.getBlogList()
    setPostList(list)
    setLoading(false)
  }

  const deleteItem = async (id: number) => {
    setLoading(true)
    await BlogApi.deleteBlog(id)
    const list = await BlogApi.getBlogList()
    setPostList(list)
    setLoading(false)
  }

  const editItem = async (id: number) => {
    if (!fileInput && title === currentItem?.name && text === currentItem?.text && titleEn === currentItem?.name_en && textEn === currentItem?.text_en) {
      toast.error('Nothing to edit')
    } else {
      setLoading(true)
      const body: { name: string, name_en: string, text: string, text_en: string, image?: Blob } = { name: title, text, name_en: titleEn, text_en: textEn }
      if (fileInput)
        body.image = fileInput
      const response = await BlogApi.editBlog(id, body)
      if (!response.success) {
        return setLoading(false)
      }
      const list = await BlogApi.getBlogList()
      setPostList(list)
      setCurrentItem(null)
      setLoading(false)
    }
  }

  return currentItem
    ? <div className={styles.content}>
      <PanelHeader location={'Изменить статью'} />
      <PanelSubjectTitle
        title={title}
        titleEn={titleEn}
        titlePlaceholder={currentItem?.name}
        titlePlaceholderEn={currentItem?.name_en}
        setTitle={setTitle}
        setTitleEn={setTitleEn}
      />
      <PanelFileInput
        fileInput={fileInput}
        setFileInput={setFileInput}
      />
      <PostBody
        id={currentItem.id}
        text={text}
        textEn={textEn}
        setText={setText}
        setTextEn={setTextEn}
        edit={(id: number) => editItem(id)}
        cancel={() => setCurrentItem(null)} />
    </div>
    : <AllPost
      postList={postList}
      deleteItem={deleteItem}
      setCurrentItem={setCurrentItem}
    />
}

export default compose<any>(WithSideBar, WithPreloader)(AllPostContainer);