import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { compose } from "redux";
import WithPreloader from "../../hoc/WithPreloader";
import { IWithPreloader } from "../../hoc/WithPreloader/interfaces";
import WithSideBar from "../../hoc/WithSideBar";
import { BlogApi } from "../../api/api";
import AddPost from ".";



const AddPostContainer: React.FC<IWithPreloader> = ({ setLoading }) => {
  const [fileInput, setFileInput] = useState<Blob | null>(null)
  const [text, setText] = useState<string>('')
  const [textEn, setTextEn] = useState<string>('')
  const [titleEn, setTitleEn] = useState<string>('')
  const [title, setTitle] = useState<string>('')
  const history = useHistory();

  const publish = async () => {
    if (text && textEn && title && titleEn) {
      setLoading(true)
      localStorage.removeItem('add-post')
      if (fileInput) {
        await BlogApi.createBlog({ text, text_en: textEn, name: title, name_en: titleEn, image: fileInput })
      } else {
        await BlogApi.createBlog({ text, text_en: textEn, name: title, name_en: titleEn })
      }
      setLoading(false)
      history.push('/all-post')
    } else {
      toast.error('Fill in all fields')
    }
  }

  const save = () => {
    localStorage.setItem('add-post', JSON.stringify({ title, text, textEn, titleEn}))
    toast.success('Saved')
  }

  useEffect(() => {
    const cashedData = JSON.parse(localStorage.getItem('add-post') as string)
    if (cashedData) {
      setText(cashedData.text)
      setTitle(cashedData.title)
      setTextEn(cashedData.textEn)
      setTitleEn(cashedData.titleEn)
    }
  }, [])

  return <AddPost
    fileInput={fileInput}
    text={text}
    title={title}
    textEn={textEn}
    titleEn={titleEn}
    save={save}
    publish={publish}
    setTitle={setTitle}
    setTextEn={setTextEn}
    setTitleEn={setTitleEn}
    setFileInput={setFileInput}
    setText={setText}
  />
}

export default compose<any>(WithSideBar, WithPreloader)(AddPostContainer);