import SideBarContainer from '../../components/SideBar/container';
import styles from './index.module.scss';

const WithSideBar = (Component: React.FC<{}>) => {

	const WithSideBarCom = () => (<>
		<SideBarContainer />
		<div className={styles.sideBarGap}>
			<Component />
		</div>
	</>)
	return WithSideBarCom;
};

export default WithSideBar;