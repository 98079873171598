import styles from './index.module.scss';
import { ITextInput } from './interfaces';

const TextInput: React.FC<ITextInput> = ({ type, placeholder, value, onChange, className, isChecked }) => (
  <input
    type={type}
    placeholder={placeholder}
    className={`${styles.textInput} ${className}`}
    value={value}
    onChange={onChange}
    style={isChecked ? { borderColor: 'red' } : undefined}
  />
);

export default TextInput;