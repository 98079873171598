import { toast } from 'react-toastify';
import styles from './index.module.scss';
import { IVacancyBody } from './interfaces';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from '@fortawesome/free-solid-svg-icons'

const VacancyBody: React.FC<IVacancyBody> = ({id, skills, requirements, setSkills, setRequirements, save, publish, edit, cancel }) => (
  <div className={`${styles.panelBody} ${styles.panel}`}>
    <h3>Навыки</h3>
    <div className={styles.skills}>
      <div className={styles.skillsEng}>
        <h4>English</h4>
        <div className={styles.todo}>
          <div className={styles.todo__input}>
            <input id={'skill'} className={styles.todo__text} type="text" />
            <span
              onClick={() => {
                const skill = (document.getElementById('skill') as HTMLInputElement).value
                if (!skill) {
                  toast.error('Fill in the skill field')
                } else {
                  setSkills((oldState) => [...oldState, skill]);
                  (document.getElementById('skill') as HTMLInputElement).value = '';
                }
              }}
              className={styles.todo__add}
            ></span>
          </div>
          {skills.length > 0 && <ul className={styles.todo__items}>
            {skills.map((skill, index) => <li key={index} className={styles.todo__item}>
              <span>
                {skill}
              </span>
              <FontAwesomeIcon
                icon={faTrash}
                color={'red'}
                onClick={() => {
                  const newSkills = [...skills]
                  newSkills.splice(index, 1)
                  setSkills(newSkills)
                }} />
            </li>)}
          </ul>}
        </div>
      </div>
    </div>
    <h3>Требования</h3>
    <div className={styles.twoRequirements}>
      <div className={styles.requirementsRu}>
        <h4>Украинский</h4>
        <div className={styles.todo}>
          <div className={styles.todo__input}>
            <input id={'ukRequirement'} className={styles.todo__text} type="text" />
          </div>
          <ul className={styles.todo__items}>
            {requirements.length > 0 && requirements.map((requirement, index) =>
              <li key={index} className={styles.todo__item}>
                <span>
                  {requirement.uk}
                </span>
                <FontAwesomeIcon
                  icon={faTrash}
                  color={'red'}
                  onClick={() => {
                    const newRequirements = [...requirements]
                    newRequirements.splice(index, 1)
                    setRequirements(newRequirements)
                  }} />
              </li>)}
          </ul>
        </div>
      </div>
      <div className={styles.requirementsEng}>
        <h4>English</h4>
        <div className={styles.todo}>
          <div className={styles.todo__input}>
            <input id={'enRequirement'} className={styles.todo__text} type="text" />
          </div>
          <ul className={styles.todo__items}>
            {requirements.length > 0 && requirements.map((requirement, index) => <li key={index} className={styles.todo__item}>
              <span>
                {requirement.en}
              </span>
              <FontAwesomeIcon
                icon={faTrash}
                color={'red'}
                onClick={() => {
                  const newRequirements = [...requirements]
                  newRequirements.splice(index, 1)
                  setRequirements(newRequirements)
                }} />
            </li>)}
          </ul>
        </div>
      </div>
      <span
        onClick={() => {
          const ukRequirement = (document.getElementById('ukRequirement') as HTMLInputElement).value
          const enRequirement = (document.getElementById('enRequirement') as HTMLInputElement).value
          if (!ukRequirement || !enRequirement) {
            toast.error('Fill in the requirements fields')
          } else {
            setRequirements((oldState) => [...oldState, { uk: ukRequirement, en: enRequirement }]);
            (document.getElementById('ukRequirement') as HTMLInputElement).value = '';
            (document.getElementById('enRequirement') as HTMLInputElement).value = '';
          }
        }}
        className={`${styles.todo__add} ${styles.todo_out__add}`}
      ></span>
    </div>
    <div id="save-action">
      {edit ? <>
        <button className="button" onClick={() => edit(id as number)} >Изменить</button>
        <button className="button" onClick={cancel} >Отменить</button>
      </>
        : <>
          <button className="button" onClick={save} >Сохранить</button>
          <button className="button" onClick={publish}>Опубликовать</button>
        </>
      }
    </div>
  </div>
);

export default VacancyBody;