import { IPanelFileInput, IPanelSubjectTitle, IProjectBody } from '../../components/AddSubjects/interfaces';
import styles from '../../components/AddSubjects/index.module.scss';
import PanelHeader from '../../components/AddSubjects/panelHeader';
import PanelSubjectTitle from '../../components/AddSubjects/panelSubjectTitle';
import PanelFileInput from '../../components/AddSubjects/panelFileInput';
import ProjectBody from '../../components/AddSubjects/projectBody';

const AddProject: React.FC<IPanelFileInput & IPanelSubjectTitle & IProjectBody> = ({ title, titleEn, text,textEn, fileInput, save, publish, setTitle, setTitleEn, setText,setTextEn, setFileInput }) => (
  <div className={styles.content}>
    <PanelHeader location={'Добавить проект'} />
    <PanelSubjectTitle
      title={title}
      titleEn={titleEn}
      titlePlaceholder={'Название проекта'}
      setTitle={setTitle}
      setTitleEn={setTitleEn}
    />
    <PanelFileInput
      fileInput={fileInput}
      setFileInput={setFileInput}
    />
    <ProjectBody
      text={text}
      textEn={textEn}
      save={save}
      setTextEn={setTextEn}
      publish={publish}
      setText={setText}
    />
  </div>
);

export default AddProject;