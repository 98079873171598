import styles from './index.module.scss';
import { IPostBody } from './interfaces';

const PostBody: React.FC<IPostBody> = ({ id, text, textEn, setText, setTextEn, save, publish, edit, cancel }) => (
  <div className={`${styles.panelBody} ${styles.panel}`}>
    <h3>Текст статьи</h3>
    <div>
      <h4 className={styles.title}>
        Украинский язык
      </h4>
      <textarea value={text} onChange={(event) => setText(event.target.value)} className={styles.post_textarea} rows={20} name="content" id="post_content"></textarea>
    </div>
    <div>
      <h4 className={styles.title}>
        Англиский язык
      </h4>
      <textarea value={textEn} onChange={(event) => setTextEn(event.target.value)} className={styles.post_textarea} rows={20} name="content" id="post_content"></textarea>
    </div>
    <div id="save-action">
      {edit ? <>
        <button className="button" onClick={() => edit(id as number)} >Изменить</button>
        <button className="button" onClick={cancel} >Отменить</button>
      </>
        : <>
          <button className="button" onClick={save} >Сохранить</button>
          <button className="button" onClick={publish}>Опубликовать</button>
        </>
      }
    </div>
  </div>
);

export default PostBody;