import React, { useEffect, useState } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import useToken from '../../helpers/useToken';
import { IPrivateRoute } from './interfaces';
import Login from '../../pages/Login/container';

// function withHeaderAndFooter<T>(Component: React.ComponentType<T>)

const PrivateRoute: IPrivateRoute = ({ children, ...rest }) => {
	const [token, setToken] = useToken();

	return (
		<Route {...rest} render={({ location }) => (
			!!token === true
				? location.pathname === '/login'
					? <Redirect to='/orders' />
					: children
				: <Login setToken={setToken} />
		)} />
	)
}
export default PrivateRoute;