
import AllSubjects from '../../components/AllSubjects';
import styles from './index.module.scss';
import { IAllProject } from './interfaces';

const AllProject: React.FC<IAllProject> = ({ projectList, deleteItem, setCurrentItem }) => (
  <AllSubjects
    panelAdd={{ link: '/add-project', text: 'Добавить проект' }}
    breadcrumbsTitle={'Портфолио'}
    panelTitle={'Все проекты'}
    itemArray={projectList}
    deleteItem={deleteItem}
    setCurrentItem={setCurrentItem}
  />
);

export default AllProject;