import styles from './index.module.scss';
import { IPanelSubjectTitle } from './interfaces';

const PanelSubjectTitle: React.FC<IPanelSubjectTitle> = ({ title, titleEn, titlePlaceholder = '', titlePlaceholderEn, setTitle, setTitleEn }) => (
    <div className={`${styles.panel} ${styles.box}`}>
        <div className={styles.panelHeading}>
            {setTitleEn && <div>Украинский язык</div>}
            <input className={styles.panelTitle} type="text" name="vacancy_title" size={30} onChange={(event) => setTitle(event.target.value)} value={title} id="title" placeholder={titlePlaceholder} />
        </div>
        {setTitleEn && <div className={styles.panelHeading}>
            <div>Англиский язык</div>
            <input className={styles.panelTitle} type="text" name="vacancy_title_en" size={30} onChange={(event) => setTitleEn(event.target.value)} value={titleEn} id="title" placeholder={titlePlaceholderEn || titlePlaceholder} />
        </div>}
    </div>
);

export default PanelSubjectTitle;