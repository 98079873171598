import { NavLink } from 'react-router-dom';
import styles from './index.module.scss';
import Avatar from '../../assets/img/avatar.jpg';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faHome, faPenSquare, faAngleRight, faCheckSquare } from '@fortawesome/free-solid-svg-icons'
import { ISideBar } from './interfaces';

const SideBar: React.FC<ISideBar> = ({getNavLinkClass}) => (<>
  <nav className={styles.navbarAdmin}>
    <ul className={styles.userNav}>
      <li className={styles.userName}><span>Admin Test</span></li>
      <li className={styles.userImg}>
        <img src={Avatar} className={styles.imgAvatar} alt="user avatar" />
        {/* <li><a href="">
          <span className={`fa faPowerOff}`}></span></a></li> */}
      </li>
    </ul>
  </nav>
  <div className={styles.adminLeftMenu}>
    <ul className={styles.navList}>
      <li className={`${styles.ripple}`}>
        <NavLink exact activeClassName={styles.active} to="/">
          <FontAwesomeIcon icon={faHome} />
          Консоль</NavLink>
      </li>
      <li className={styles.ripple}>
        <a className={`${styles.linkAdminLeftMenu}${getNavLinkClass(['/all-vacancy', '/add-vacancy'])}`}>
          <div>
            <FontAwesomeIcon icon={faUser} />
            Вакансии
          </div>
          <FontAwesomeIcon className={styles.rightArrow} icon={faAngleRight} />
        </a>
        <ul className={styles.subMenu}>
          <li><NavLink activeClassName={styles.active} to='/all-vacancy'>Все вакансии</NavLink></li>
          <li><NavLink activeClassName={styles.active} to='/add-vacancy'>Добавить вакансию</NavLink></li>
        </ul>
      </li>
      <li className={styles.ripple}>
        <a className={`${styles.linkAdminLeftMenu}${getNavLinkClass(['/all-project', '/add-project'])}`}>
          <div>
            <FontAwesomeIcon icon={faPenSquare} />
            Портфолио
          </div>
          <FontAwesomeIcon className={styles.rightArrow} icon={faAngleRight} />
        </a>
        <ul className={styles.subMenu}>
          <li><NavLink activeClassName={styles.active} to='/all-project'>Все проекты</NavLink></li>
          <li><NavLink activeClassName={styles.active} to='/add-project'>Добавить проект</NavLink></li>
        </ul>
      </li>
      <li className={styles.ripple}>
        <a className={`${styles.linkAdminLeftMenu}${getNavLinkClass(['/all-post', '/add-post'])}`}>
          <div>
            <FontAwesomeIcon icon={faCheckSquare} />
            Блог
          </div>
          <FontAwesomeIcon className={styles.rightArrow} icon={faAngleRight} />
          {/* </span> */}
        </a>
        <ul className={styles.subMenu}>
          <li><NavLink activeClassName={styles.active} to='/all-post'>Все статьи</NavLink></li>
          <li><NavLink activeClassName={styles.active} to='/add-post'>Добавить статью</NavLink></li>
        </ul>
      </li>
    </ul>
  </div>
</>);

export default SideBar;