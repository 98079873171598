import { useEffect, useState } from "react";
import { compose } from "redux";
import AllProject from ".";
import { ProjectApi } from "../../api/api";
import WithPreloader from "../../hoc/WithPreloader";
import { IWithPreloader } from "../../hoc/WithPreloader/interfaces";
import WithSideBar from "../../hoc/WithSideBar";
import { IProject } from "./interfaces";
import styles from "../../components/AddSubjects/index.module.scss";
import ProjectBody from "../../components/AddSubjects/projectBody";
import PanelFileInput from "../../components/AddSubjects/panelFileInput";
import PanelSubjectTitle from "../../components/AddSubjects/panelSubjectTitle";
import PanelHeader from "../../components/AddSubjects/panelHeader";
import { toast } from "react-toastify";

const AllProjectContainer: React.FC<IWithPreloader> = ({ setLoading }) => {

  const [projectList, setProjectList] = useState<Array<IProject> | null>(null)
  const [currentItem, setCurrentItem] = useState<IProject | null>(null)
  const [fileInput, setFileInput] = useState<Blob | null>(null)
  const [title, setTitle] = useState<string>('')
  const [titleEn, setTitleEn] = useState<string>('')
  const [text, setText] = useState<string>('')
  const [textEn, setTextEn] = useState<string>('')

  useEffect(() => {
    if (currentItem) {
      setTitle(currentItem.name)
      setTitleEn(currentItem.name_en)
      setText(currentItem.text)
      setTextEn(currentItem.text_en)
    }
  }, [currentItem])

  useEffect(() => {
    getInitialData()
  }, [])

  const getInitialData = async () => {
    setLoading(true)
    const list = await ProjectApi.getProjectList()
    setProjectList(list)
    setLoading(false)
  }

  const deleteItem = async (id: number) => {
    setLoading(true)
    await ProjectApi.deleteProject(id)
    const list = await ProjectApi.getProjectList()
    setProjectList(list)
    setLoading(false)
  }

  const editItem = async (id: number) => {
    setLoading(true)
    const body: { name: string, name_en: string, text: string, text_en: string, image?: Blob } = { name: title, name_en: titleEn, text: text.toString(), text_en: textEn.toString(), }
    if (fileInput)
      body.image = fileInput
    const response = await ProjectApi.editProject(id, body)
    if (!response.success) {
      return setLoading(false)
    }
    const list = await ProjectApi.getProjectList()
    setProjectList(list)
    setCurrentItem(null)
    setLoading(false)
  }

  return currentItem ? <div className={styles.content}>
    <PanelHeader location={'Изменить проект'} />
    <PanelSubjectTitle
      title={title}
      titleEn={titleEn}
      titlePlaceholder={currentItem.name}
      titlePlaceholderEn={currentItem.name_en}
      setTitle={setTitle}
      setTitleEn={setTitleEn}
    />
    <PanelFileInput
      fileInput={fileInput}
      setFileInput={setFileInput}
    />
    <ProjectBody
      id={currentItem.id}
      text={text}
      textEn={textEn}
      setText={setText}
      setTextEn={setTextEn}
      edit={(id: number) => editItem(id)}
      cancel={() => setCurrentItem(null)} />
  </div>
    : <AllProject
      projectList={projectList}
      deleteItem={deleteItem}
      setCurrentItem={setCurrentItem}
    />
}

export default compose<any>(WithSideBar, WithPreloader)(AllProjectContainer);