import { useState } from 'react';
import { connect } from 'react-redux';
import Preloader from '../../components/Preloader';
import { ILoginContainer } from '../../pages/Login/interfaces';
import { IWithPreloader } from './interfaces';


const WithPreloader = (Component: React.FC<IWithPreloader>) => {

	const WithPreloaderCom: React.FC = (hocProps) => {
		const [isLoading, setLoading] = useState(false)
		return <>
			{isLoading && <Preloader />}
			<Component {...hocProps} setLoading={setLoading} />
		</>
	}

	return WithPreloaderCom
}

export default WithPreloader;