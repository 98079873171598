import { useEffect, useState } from "react";
import { compose } from "redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import WithPreloader from "../../hoc/WithPreloader";
import { IWithPreloader } from "../../hoc/WithPreloader/interfaces";
import WithSideBar from "../../hoc/WithSideBar";
import { ProjectApi } from "../../api/api";
import AddProject from ".";

const AddProjectContainer: React.FC<IWithPreloader> = ({ setLoading }) => {
  const [fileInput, setFileInput] = useState<Blob | null>(null)
  const [title, setTitle] = useState<string>('')
  const [titleEn, setTitleEn] = useState<string>('')
  const [text, setText] = useState<string>('')
  const [textEn, setTextEn] = useState<string>('')
  const history = useHistory();
  
  const publish = async () => {
    if (title && titleEn && text !== '<p><br></p>' && textEn !=='<p><br></p>') {
      setLoading(true)
      localStorage.removeItem('add-project')
      if (fileInput) {
        await ProjectApi.createProject({ text, name: title, name_en: titleEn, text_en: textEn.toString(), image: fileInput })
      } else {
        await ProjectApi.createProject({ text, name: title, name_en: titleEn, text_en: textEn.toString() })
      }
      setLoading(true)
      history.push('/all-project')
    } else {
      toast.error('Fill in all fields')
    }
  }

  const save = () => {
    localStorage.setItem('add-project', JSON.stringify({ title, titleEn, text, textEn }))
    toast.success('Saved')
  }

  useEffect(() => {
    const cashedData = JSON.parse(localStorage.getItem('add-project') as string)
    if (cashedData) {
      setText(cashedData.text);
      setTitle(cashedData.title);
      setTextEn(cashedData.textEn);
      setTitleEn(cashedData.titleEn);
    }
  }, [])

  return <AddProject
    fileInput={fileInput}
    title={title}
    titleEn={titleEn}
    textEn={textEn}
    text={text}
    save={save}
    publish={publish}
    setText={setText}
    setTitle={setTitle}
    setTitleEn={setTitleEn}
    setTextEn={setTextEn}
    setFileInput={setFileInput}
  />
}

export default compose<any>(WithSideBar, WithPreloader)(AddProjectContainer);