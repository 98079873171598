import { useState } from 'react';
import instance from '../api/instance';

export default () => {
    const getToken = () => {
        const tokenString = localStorage.getItem('JellyWorkzApiToken') as string;
        return tokenString
    };

    const [token, setToken] = useState(getToken());

    const saveToken = (userToken: string) => {
        localStorage.setItem('JellyWorkzApiToken', JSON.stringify(userToken));
        instance.defaults.headers.common['authorization'] = `Bearer ${userToken}`;
        setToken(userToken);
    };

    return [token, saveToken]
}