import { compose } from "redux";
import Dashboard from ".";
import WithPreloader from "../../hoc/WithPreloader";
import { IWithPreloader } from "../../hoc/WithPreloader/interfaces";
import WithSideBar from "../../hoc/WithSideBar";

const DashboardContainer: React.FC<IWithPreloader> = ({setLoading}) => {
  
  return <Dashboard/>
}

export default compose<any>(WithSideBar, WithPreloader)(DashboardContainer);